
import { ModelSelect, ModelListSelect  } from 'vue-search-select'
import 'vue-search-select/dist/VueSearchSelect.css'

import DATA_MASTER from  '../../library/dataMaster'
import FETCHING from '../../library/fetching'
import UMUM from "../../library/umum.js";
  

export default {
   components: {
    ModelSelect, ModelListSelect, 
  },
  data() {
    return {


    
      form : {
          id : '',
          tahun : '',
          uraian : '',
          keterangan : '',
          file : null,
          file_type : '',
          keterangan : '',
          unit_kerja_id : '',
          
           file_old : null,
        },

        file_path: this.$store.state.url.URL_APP + "uploads/",

      list_data : [],
      lokasi : [],
      cariOPD:'',

      // ====================================== CONTOH AUTOCOMPLETE ====================================
      
    
      cek_load_data : true,
      
      unit_kerja : '', 
      unit_kerja_id : '',
      tipe: 0,

    
       mdl_add : false,
        mdl_edit : false,
        mdl_hapus : false,
        mdl_lihat : false,
        mdl_add_opd : false,

        
        mdl_open : false,

        page_first: 1,
        page_last: 0,
        page_limit : 5,
        cari_value: "",
        FETCHING : FETCHING,
        UMUM : UMUM,
        
      DATA_MASTER : DATA_MASTER, 
      

    }
  },

  methods: {

     getcheckAll : function () {

        console.log("===========");
          // this.lokasi = this.$store.state.listOPD

        if (this.checkAll==true) {
          
          this.lokasi = this.$store.state.listOPD
          this.checkAll = false
        } else {
          this.lokasi = []
          this.checkAll = true
          
        }

    },
    
    getView : function(){
      this.$store.commit("shoWLoading");
      fetch(this.$store.state.url.URL_publish + "view", {
            method: "POST",
            headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
                
                data_ke: this.page_first,
                cari_value: this.cari_value,
                page_limit : this.page_limit,
                unit_kerja : this.unit_kerja_id,
                tipe_user : this.tipe
            })
        })
          .then(res => res.json())
          .then(res_data => {
              this.list_data = res_data.data;
              console.log(res_data);
              this.page_last = res_data.jml_data;
              this.$store.commit("hideLoading");
              console.log(res_data);
      });
    },

     lokasiData : function(id){
      
          fetch(this.$store.state.url.URL_direktori + "lokasi", {
              method: "POST",
              headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
              },
              body: JSON.stringify({
                  
                  id_direktori : id
              })
          })
            .then(res => res.json())
            .then(res_data => {
                this.lokasi = res_data.data;
                
                this.$store.commit("hideLoading");
                
        });
    },

       addData: function(){
 
        // var get_profile = JSON.parse(localStorage.profile);
        // this.lampiran.unit_kerja_id = get_profile.profile.unit_kerja; 
        // this.form.lokasi = this.lokasi
        console.log(this.lokasi);
        var formData = new FormData();
        formData.append('pb', JSON.stringify( this.form  ));
        formData.append('file', this.form.file);
        formData.append('lokasi', JSON.stringify(this.lokasi));

        fetch(this.$store.state.url.URL_publish + 'addData', {
            method: "POST",
            headers: {
                authorization: "kikensbatara " + localStorage.token
            },
            body: formData,

            }).then(res_data => {

              
              // this.lampiran.direktori_id
               this.Notify ('Sukses Menambah Data', 'primary', 'check_circle_outline');
              this.getView();
        });
      },

     


     editData: function(){

        var formData = new FormData();
        formData.append('pb', JSON.stringify(this.form));
        formData.append('file', this.form.file);
        // formData.append('fileOld', this.fileOld);

        fetch(this.$store.state.url.URL_publish + 'editData', {
            method: "POST",
            headers: {
                authorization: "kikensbatara " + localStorage.token
            },
            body: formData
            }).then(res_data => {
              this.Notify ('Sukses Mengubah Data', 'primary', 'check_circle_outline');
              this.getView();
        });
      },
  
    removeData : function(E){
      fetch(this.$store.state.url.URL_publish + "removeData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({id : this.form.id})
      }).then(res_data => {
        // this.history
        this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
        this.getView();
      });

    },

    async history(){
        var a = await FETCHING.POST_DATA(this.$store.state.url.URL_history + "view")  
        console.log(a)
        this.list_data = a.data 
    },

     hapusArray : function(data){
      var arr = this.lokasi
      const objWithIdIndex = arr.findIndex((obj) => obj.uniqid === data.uniqid);
      console.log(objWithIdIndex);
      this.lokasi.splice(objWithIdIndex, 1);
    },


   selectData : function(data){

          this.form.id= data.id;
          this.form.tahun = data.tahun;
          this.form.uraian = data.uraian;
          this.form.keterangan = data.keterangan;
        this.form.file_old =  data.file
        
        this.form.file = this.form.file
          this.form.unit_kerja_id = data.unit_kerja_id;
      },

    
      
    // ====================================== CONTOH eDOC ====================================
    onClickx(data){
      alert(data)
    },
    // ====================================== CONTOH eDOC ====================================


    // ====================================== PAGINATE ====================================
        Notify : function(message, positive, icon){
          this.$q.notify({
            message: message,
            color: positive,
            icon: icon,
            position : 'top',
            timeout: 500,
          })
        },
        btn_prev : function(){
            this.cek_load_data = true;
            if(this.page_first>1){
                this.page_first--
            }else{
                this.page_first = 1;
            }
            this.getView();
        },

        btn_next : function(){
            if(this.page_first >= this.page_last){
                this.page_first == this.page_last
            }else{
                this.page_first++;
            }
            this.getView();
        },

        cari_data : function(){
            this.page_first = 1;
            this.getView();
        },

        indexing : function(index){
            var idx = ((this.page_first-1)*this.page_limit)+index
            return idx
        },
        iniopd : async function(){
          this.$store.state.listOPD = await FETCHING.FETCH_POST(this.$store.state.url.URL_simpeg_unit_kerja + "list", {cari : this.cariOPD})
        },

    // ====================================== PAGINATE ====================================

  },

  mounted () {
    
      var get_profile = JSON.parse(localStorage.profile);
      this.form.unit_kerja_id = get_profile.profile.unit_kerja;
      this.unit_kerja_id = get_profile.profile.unit_kerja;

      this.tipe = get_profile.profile.trista_monev;

      this.getView();
       this.iniopd();
 
    // this.getView();
  },
}
